import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  addComment,
  fetchStructuredLogs,
} from "../../../redux/actions/conversationsActions";
import { Message, Comment } from "../../../redux/reducers/conversationsReducer";
import { getTargetId } from "../../../utils/render";
import { format } from "date-fns";
import CryptoJS from "crypto-js";
import MessageContent from "./message-bubble/MessageContent";
import CommentsSection from "./message-bubble/CommentsSection";
import AddCommentModal from "./message-bubble/AddCommentModal";
import StructuredLogsModal from "./message-bubble/StructuredLogsModal";

interface MessageBubbleProps {
  message: Message;
  chatSessionId: string;
  isRightAligned: boolean;
  conversationId?: string;
  canViewAllData?: boolean;
  comments?: Comment[];
  onHeightChange?: (height: number) => void;
  isHighlighted?: boolean;
  highlightedCommentId?: string | null;
  searchTerm?: string;
  onCommentAdded?: () => void;
  getContextMessages: (messageId: number, chatSessionId: string) => Message[];
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  isRightAligned,
  conversationId,
  canViewAllData,
  comments = [],
  chatSessionId,
  onHeightChange,
  onCommentAdded,
  highlightedCommentId,
  searchTerm,
  getContextMessages,
  isHighlighted,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user: adminUser } = useSelector((state: RootState) => state.base);

  // Permissions
  const canViewModelCalls = adminUser?.scopes?.includes("structuredlog:read");
  const canViewMessageAudio = adminUser?.scopes?.includes("storedaudio:read");

  // State
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showComments, setShowComments] = useState(true);
  const [showShareTooltip, setShowShareTooltip] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  const messageId = message.id ?? message.chat_message_id;
  const chatSessionIdFromMessage = message.chat_session_id ?? chatSessionId;
  const targetId = getTargetId(messageId, chatSessionIdFromMessage);

  const structuredLogs = useSelector(
    (state: RootState) => state.conversations.structuredLogs[targetId]
  );
  const loadingStructuredLogs = useSelector(
    (state: RootState) => state.conversations.loadingStructuredLogs[targetId]
  );

  const bubbleRef = useRef<HTMLDivElement>(null);

  // Recalculate height on changes
  useEffect(() => {
    if (bubbleRef.current && onHeightChange) {
      const height = bubbleRef.current.getBoundingClientRect().height;
      onHeightChange(height);
    }
  }, [
    onHeightChange,
    imageUrl,
    showComments,
    commentText,
    message.content,
    loadingImage,
    comments.length,
  ]);

  useEffect(() => {
    // If we have a highlightedCommentId, ensure comments are expanded
    if (highlightedCommentId) {
      const hasHighlightedComment = comments.some(
        (comment) => comment.id === highlightedCommentId
      );
      if (hasHighlightedComment) {
        setShowComments(true);
      }
    }
  }, [highlightedCommentId, comments]);

  // Threads logic
  const threadsMap = comments.reduce((threads, comment) => {
    if (!threads[comment.thread_id]) {
      threads[comment.thread_id] = [];
    }
    threads[comment.thread_id].push(comment);
    return threads;
  }, {} as Record<string, Comment[]>);

  const threads = Object.values(threadsMap).sort((a, b) => {
    const latestA = new Date(
      a.reduce(
        (max, comment) =>
          new Date(comment.created_at) > new Date(max)
            ? comment.created_at
            : max,
        a[0].created_at
      )
    );
    const latestB = new Date(
      b.reduce(
        (max, comment) =>
          new Date(comment.created_at) > new Date(max)
            ? comment.created_at
            : max,
        b[0].created_at
      )
    );
    return latestB.getTime() - latestA.getTime();
  });

  const handleAddComment = (tags: string[]) => {
    if (
      conversationId &&
      (message?.id != null || message.chat_message_id != null) &&
      commentText.trim() !== ""
    ) {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: getTargetId(
            message.id ?? message.chat_message_id,
            chatSessionId
          ),
          comment: commentText,
          tags: tags,
        })
      );
      setCommentText("");
      setShowCommentModal(false);
      setShowComments(true);
      onCommentAdded?.();
    }
  };

  const fetchImage = async () => {
    setLoadingImage(true);
    try {
      const encryptedJwt = localStorage.getItem("jwt");
      let jwt = null;

      if (encryptedJwt) {
        const bytes = CryptoJS.AES.decrypt(
          encryptedJwt,
          process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
        );
        jwt = bytes.toString(CryptoJS.enc.Utf8);
      }

      const headers: HeadersInit = { "Content-Type": "image/jpeg" };
      if (jwt) {
        headers["Authorization"] = `Bearer ${jwt}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_MEDIA_SERVER_URL}v1/media/download_media?conversation_id=${conversationId}&blob_path=${message.media_ref?.blob_path}`,
        { method: "GET", headers: headers }
      );

      if (!response.ok) {
        throw new Error("Failed to download image");
      }

      const arrayBuffer = await response.arrayBuffer();
      const base64String = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      setImageUrl(
        `data:${message.media_ref?.content_type};base64,${base64String}`
      );
    } catch (error) {
      console.error("Error fetching image:", error);
    } finally {
      setLoadingImage(false);
    }
  };

  const handleShareMessage = () => {
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_LOGIN_REDIRECT_URL
      }/conversations/${conversationId}?target_id=${getTargetId(
        message.id ?? message.chat_message_id,
        chatSessionId
      )}`
    );
    setShowShareTooltip(true);
    setTimeout(() => setShowShareTooltip(false), 2000);
  };

  const handleShowLogsModal = () => {
    setShowLogsModal(true);
    if (
      !structuredLogs &&
      !loadingStructuredLogs &&
      (message.client_msg_uid ||
        message.triggered_by_action_id ||
        message.responding_to)
    ) {
      dispatch(
        fetchStructuredLogs({
          conversationId: conversationId || "",
          chatSessionId: chatSessionIdFromMessage,
          startMsgId: messageId,
          requestedEvents: [
            { event: "llm_inference_event", orderings: ["CREATED_AT_DESC"] },
            { event: "model_inference_event", orderings: ["CREATED_AT_DESC"] },
            {
              event: "text_to_speech_saved_event",
              orderings: ["CREATED_AT_DESC"],
            },
          ],
          actionId:
            message.type === "human"
              ? message.triggered_by_action_id ?? ""
              : message.responding_to
              ? message.responding_to
              : message.triggered_by_action_id ?? "",
          clientMsgUid: message.client_msg_uid ?? "",
        })
      );
    }
  };

  const highlightedContent = useMemo(() => {
    if (!searchTerm || !message.content) {
      return message.content;
    }
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return message.content.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }, [searchTerm, message.content]);

  const formattedDate = format(
    new Date(message.created_at),
    "MMM d, yyyy HH:mm"
  );

  return (
    <Box
      ref={bubbleRef}
      sx={{ display: "flex", alignItems: "flex-start" }}
      id={targetId}
      className={isRightAligned ? "bot-bubble" : "human-bubble"}
    >
      <Box position="relative" flex={1}>
        <MessageContent
          message={message}
          isRightAligned={isRightAligned}
          canViewAllData={canViewAllData}
          imageUrl={imageUrl}
          loadingImage={loadingImage}
          fetchImage={fetchImage}
          highlightedContent={highlightedContent}
          formattedDate={formattedDate}
          canViewMessageAudio={canViewMessageAudio}
          showShareTooltip={showShareTooltip}
          handleShareMessage={handleShareMessage}
          comments={comments}
          showComments={showComments}
          setShowComments={setShowComments}
          canViewModelCalls={canViewModelCalls}
          handleShowLogsModal={handleShowLogsModal}
          setShowCommentModal={setShowCommentModal}
          isHighlighted={isHighlighted}
        />

        <CommentsSection
          threads={threads}
          showComments={showComments}
          setShowComments={setShowComments}
          conversationId={conversationId}
          searchTerm={searchTerm}
          onCommentChange={() => onCommentAdded?.()}
          onHeightChange={() => {
            if (bubbleRef.current && onHeightChange) {
              const height = bubbleRef.current.getBoundingClientRect().height;
              onHeightChange(height);
            }
          }}
        />

        {canViewModelCalls && (
          <StructuredLogsModal
            showLogsModal={showLogsModal}
            setShowLogsModal={setShowLogsModal}
            structuredLogs={structuredLogs}
            loadingStructuredLogs={loadingStructuredLogs}
            message={message}
            getContextMessages={getContextMessages} // pass the function down
          />
        )}

        <AddCommentModal
          showCommentModal={showCommentModal}
          setShowCommentModal={setShowCommentModal}
          commentText={commentText}
          setCommentText={setCommentText}
          handleAddComment={(tags) => {
            handleAddComment(tags);
          }}
        />
      </Box>
    </Box>
  );
};

export default MessageBubble;
