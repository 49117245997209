import React, { useRef } from "react";
import { Typography, Box } from "@mui/material";
import {
  VariableSizeList as WindowList,
  ListChildComponentProps,
} from "react-window";
import MessageBubble from "../MessageBubble";
import SessionAudio from "../audio/SessionAudio";
import { Message } from "../../../../redux/reducers/conversationsReducer";

interface ItemData {
  listItems: any[];
  getCommentsForMessage: (targetId: string) => any[];
  conversationId: string | undefined;
  showRealData: boolean;
  canReadConversation?: boolean;
  itemHeights: React.MutableRefObject<Record<number, number>>;
  listRef: React.RefObject<any>;
  highlightedItemId: string | null;
  highlightedCommentId: string | null;
  searchTerm: string;
  adminUser: any;
  setPreventTargetScroll: (val: boolean) => void;
  getContextMessages: (messageId: number, chatSessionId: string) => Message[];
}

interface MessageListProps {
  listItems: any[];
  getCommentsForMessage: (targetId: string) => any[];
  conversationId: string | undefined;
  showRealData: boolean;
  canReadConversation?: boolean;
  itemHeights: React.MutableRefObject<Record<number, number>>;
  listRef: React.RefObject<any>;
  highlightedItemId: string | null;
  highlightedCommentId: string | null;
  searchTerm: string;
  adminUser: any;
  setPreventTargetScroll: (val: boolean) => void;
  handleScroll: (props: any) => void;
  getItemSize: (index: number) => number;
}

// Helper to get message items around a given messageId
function createGetContextMessages(
  listItems: any[]
): (messageId: number, chatSessionId: string) => Message[] {
  return (messageId: number, chatSessionId: string) => {
    // Find the index of the given messageId in listItems
    const idx = listItems.findIndex(
      (item) =>
        item.type === "message" &&
        item?.message?.chat_session_id === chatSessionId &&
        (item?.message?.id === messageId ||
          item?.message?.chat_message_id === messageId)
    );
    if (idx === -1) return [];

    // We want 7 before and 7 after
    const start = Math.max(idx - 7, 0);
    const end = Math.min(idx + 7, listItems.length - 1);

    const contextMessages: Message[] = [];

    for (let i = start; i <= end; i++) {
      const item = listItems[i];
      if (item.type === "message") {
        contextMessages.push(item.message);
      }
    }

    return contextMessages;
  };
}

const MessageList: React.FC<MessageListProps> = ({
  listItems,
  getCommentsForMessage,
  conversationId,
  showRealData,
  canReadConversation,
  itemHeights,
  listRef,
  highlightedItemId,
  highlightedCommentId,
  searchTerm,
  adminUser,
  setPreventTargetScroll,
  handleScroll,
  getItemSize,
}) => {
  // Create the context message function once
  const getContextMessages = createGetContextMessages(listItems);

  const ListItemRenderer: React.FC<ListChildComponentProps<ItemData>> = ({
    index,
    style,
    data,
  }) => {
    const {
      listItems,
      getCommentsForMessage,
      conversationId,
      showRealData,
      canReadConversation,
      itemHeights,
      listRef,
      highlightedItemId,
      highlightedCommentId,
      searchTerm,
      adminUser,
      setPreventTargetScroll,
      getContextMessages,
    } = data;
    const item = listItems[index];
    const itemRef = useRef<HTMLDivElement>(null);

    if (item.type === "date") {
      const isHighlighted = highlightedItemId === item.date;
      return (
        <div
          style={{
            ...style,
            overflow: "visible",
            backgroundColor: isHighlighted ? "#ffffcc" : "inherit",
          }}
          ref={itemRef}
          id={item.date}
        >
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom textAlign="center">
              {item.date}
            </Typography>
          </Box>
        </div>
      );
    } else if (item.type === "session") {
      const canViewMessageAudio =
        adminUser?.scopes?.includes("storedaudio:read");
      return (
        <div style={{ ...style, overflow: "visible" }} ref={itemRef}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ color: "gray" }}>
              New Session: {item.sessionId}
            </Typography>
            {canViewMessageAudio ? (
              <SessionAudio
                conversationId={conversationId ?? ""}
                chatSessionId={item.sessionId}
              />
            ) : null}
          </Box>
        </div>
      );
    } else if (item.type === "message") {
      const { message, targetId } = item;
      const messageComments = getCommentsForMessage(targetId);
      return (
        <div
          style={{
            ...style,
            overflow: "visible",
            width: "98%",
          }}
          ref={itemRef}
          id={targetId}
        >
          <MessageBubble
            message={message}
            conversationId={conversationId}
            isRightAligned={message.type !== "human"}
            canViewAllData={showRealData && canReadConversation}
            comments={messageComments}
            chatSessionId={message.chat_session_id}
            onHeightChange={(height) => {
              if (itemHeights.current[index] !== height) {
                itemHeights.current[index] = height;
                if (listRef.current) {
                  listRef.current.resetAfterIndex(index);
                }
              }
            }}
            onCommentAdded={() => setPreventTargetScroll(true)}
            isHighlighted={highlightedItemId === targetId}
            highlightedCommentId={highlightedCommentId}
            searchTerm={searchTerm}
            getContextMessages={getContextMessages} // Pass down the function
          />
        </div>
      );
    } else if (item.type === "spacer") {
      return (
        <div
          style={{
            ...style,
          }}
          ref={itemRef}
        />
      );
    }
    return null;
  };

  return (
    <WindowList
      ref={listRef}
      height={window.innerHeight}
      itemCount={listItems.length}
      itemSize={getItemSize}
      width="100%"
      onScroll={handleScroll}
      overscanCount={10}
      itemData={{
        listItems,
        getCommentsForMessage,
        conversationId,
        showRealData,
        canReadConversation,
        itemHeights,
        listRef,
        highlightedItemId,
        highlightedCommentId,
        searchTerm,
        adminUser,
        setPreventTargetScroll,
        getContextMessages,
      }}
    >
      {ListItemRenderer}
    </WindowList>
  );
};

export default MessageList;
