import React, { useState, KeyboardEvent } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Modal,
  Chip,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { hashTagToColor } from "../../../../utils/tagColorUtils";

interface AddCommentModalProps {
  showCommentModal: boolean;
  setShowCommentModal: (val: boolean) => void;
  commentText: string;
  setCommentText: (val: string) => void;
  handleAddComment: (tags: string[]) => void;
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({
  showCommentModal,
  setShowCommentModal,
  commentText,
  setCommentText,
  handleAddComment,
}) => {
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const suggestedTags = ["Audio", "Rewrite", "Prompt"];

  const addTag = (tag: string) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
    setTagInput("");
  };

  const removeTag = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag(tagInput.trim());
    }
  };

  const submitComment = () => {
    handleAddComment(tags ?? []);
  };

  return (
    <Modal open={showCommentModal} onClose={() => setShowCommentModal(false)}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="h2">
            Add Comment
          </Typography>
          <IconButton onClick={() => setShowCommentModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TextField
          fullWidth
          multiline
          rows={4}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          sx={{ mt: 2 }}
          label="Comment"
        />

        {/* Tags Section */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mt: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" sx={{ mt: 0, fontWeight: "bold" }}>
            Tags:
          </Typography>
          {tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              sx={{
                backgroundColor: hashTagToColor(tag),
                color: "white",
              }}
              onDelete={() => removeTag(tag)}
            />
          ))}
        </Box>

        {/* Suggested Tags */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mt: 2,
            p: 1,
            borderRadius: "16px",
            border: "2px dotted gray",
          }}
        >
          <Typography variant="subtitle2" sx={{ mt: 0, fontWeight: "italic" }}>
            Suggestions:
          </Typography>
          {suggestedTags
            .filter((tag) => !tags.includes(tag))
            .map((suggestedTag) => (
              <Chip
                key={suggestedTag}
                label={suggestedTag}
                size="small"
                variant="outlined"
                sx={{
                  borderColor: hashTagToColor(suggestedTag),
                  color: hashTagToColor(suggestedTag),
                  "&:hover": {
                    backgroundColor: hashTagToColor(suggestedTag),
                    color: "white",
                    cursor: "pointer",
                  },
                }}
                onClick={() =>
                  tags.includes(suggestedTag)
                    ? removeTag(suggestedTag)
                    : addTag(suggestedTag)
                }
              />
            ))}
        </Box>

        <Box sx={{ display: "flex", gap: 1, mt: 2, alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type a tag and press Enter"
            sx={{ flex: 1 }}
          />
          <Button variant="contained" onClick={() => addTag(tagInput.trim())}>
            Add Tag
          </Button>
        </Box>

        <Button variant="contained" onClick={submitComment} sx={{ mt: 2 }}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default AddCommentModal;
